.status-button{
    border: none;
    color: white;
    padding: 8px 20px;
    width: 135px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 40px;
}

.status-active{
    background-color: #4CAF50;
}

.status-inactive{
    background-color: rgb(237, 75, 75);
}

.modal-root {
    width: 500;
    height: 200;
    /*margin-top: 2%;*/
}

.modal-heading {
    text-align: center;
}

.modal-label {
    font-weight: 500;
    padding-left: 4%;
}

.modal-name-input {
    vertical-align: middle;
}

.modal-email-input {
    vertical-align: middle;
}

.modal-input-layout {
    display:grid;
    grid-template-columns: 1fr 5fr;
    align-items:center;
    margin-top: 20px;
}

.modal-btn-style {
    margin-left: 80%;
    background-color: #645A82 !important;
    color: white !important;
}

.modal-btn-style:hover{
    background-color: #645A82,
}

.modal-close-btn {
    top: 0;
    right: 0;
    position: absolute;
}

.modal-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

.modal-circular-progress {
    color: #E16D30;
}

.modal-input-error-container{
    position: relative;
}

.modal-error-message {
    position: absolute;
    top:40px;
    color: red;
    margin-bottom: 0.01em;
}

.invite-info-container {
    width: 600;
    height: 200;
    margin-top: 10%;
}

.invite-close-btn {
    top: 0;
    right: 0;
    position: absolute;
}
  
.invite-success {
    color: "#5FA753";
}

.invite-fail {
    color: "#ff3333";
}

.invite-info-message-container {
    display: block !important;
    width: 100%;
    text-align: center;
    margin-top: 10px
}

.invite-post-info {
    width: 20%;
    margin: "0 auto";
    margin-top: "10px";
}

.fullscreen-loader-container{
    height:100vh;
    width:100vw;
    position: fixed;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-update-heading {
    text-align: center;
}

.user-update-label {
    font-weight: 500;
    padding-left: 4%;
}

.user-update-input {
    width: 850;
}

.user-update-checkbox {
    margin-top: 20px;
}

.user-update-gridlayout{
    margin-top: 30px;
    vertical-align: middle;
}

.user-update-update-btn {
    margin-left: 30%;
    margin-top: 15px;
    background-color: #645A82;
    border: none;
    color: white;
    padding: 15px 32px;
    width: 140px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 20px;
}

.user-update-error-message {
    color: red !important;
    margin: 0;
}

.spinner-box {
  display: grid;
  place-items: center;
  min-height: 100vh;
}

.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height:100px;
}

.custom-error-class.quill > .ql-container {
  color: #f44336;
  border-color: #f44336;
}

.custom-error-class.quill > .ql-toolbar {
  border-top-color: #f44336;
  border-left-color: #f44336;
  border-right-color: #f44336;
}

.fitJobDescriptionContent .ant-form-item-control-input-content{
    min-width: calc(100vw - 200px - 48px - 12rem);
    max-width: fit-content;
}

@media (min-width: 1449px) {
    .fitJobDescriptionContent .ant-form-item-control-input-content{
        min-width: calc(1200px - 12rem);
        max-width: calc(1200px - 12rem);
    }
}